import {HttpClient} from "@angular/common/http";
import {inject, Injectable} from '@angular/core';
import {BulletPointsAI, CompanyDescAI, SkillsAI, SummaryAI} from "@app/shared/interfaces";

@Injectable({
  providedIn: 'root'
})
export class AiHelpService {
  private http = inject(HttpClient);

  constructor() {
  }

  public getSummaryAI(data: SummaryAI) {
    return this.http.post<{ text: string }>('api/v1/ai/summary', data);
  }

  public getBulletPointsAI(data: BulletPointsAI) {
    return this.http.post<string[]>('api/v1/ai/bullet-points', data);
  }

  public getCompanyDescAI(data: CompanyDescAI) {
    return this.http.post<{ text: string }>('api/v1/ai/company-description', data);
  }

  public getSkillsAI(data: SkillsAI) {
    return this.http.post<string[]>('api/v1/ai/skills', data);
  }

}

