@if (template.summary.state) {
  <div
    class="summary-part"
    [class.edit-mode]="selectedItem === template.summary"
    [class.has-icon]="template.summary.icon?.state"
    (click)="onSelect(template.summary)">

    @if (selectedItem === template.summary) {
      <ng-container [ngTemplateOutlet]="AISummary"/>

      <sf-tm-editor/>
    }

    @if (template.summary.title.state) {
      <div class="summary-title-wrap">

        @if (template.summary.icon?.state) {
          <i class="{{template.summary!.icon!.name}}"></i>
        }

        <h2
          contenteditable="true"
          [innerHTML]="template.summary.title.text"
          (blur)="onBlurSummaryTitle($event, template.summary)"
          (focus)="onFocusEL($event, template.summary.title.text)"
          [attr.placeholder]="template.summary.title.pl | translate"
          (keydown.enter)="$event.preventDefault()"
          [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
          [ngStyle]="{
           fontSize: appStateService.templateCurrentFontSize.summaryTitle + 'px',
           lineHeight: appStateService.templateCurrentFontSize.summaryTitleLineHeight + 'px',
           color: insideSidebar ? '#303030' : (selectedItem === template.summary ? tmTheme.summaryTitleFontEditModeColor : tmTheme.summaryTitleFontColor),
        }"
        ></h2>
      </div>
    }

    <p
      contenteditable="true"
      (mouseup)="handleMouseUp(template.summary.desc)"
      (sfCtrlA)="handleCtrlA(template.summary.desc)"
      (keydown.enter)="onEnterDesc($event)"
      (keydown.backspace)="onBackspaceSummary($event)"
      (keydown.delete)="onBackspaceSummary($event)"
      [innerHTML]="template.summary.desc.text"
      (blur)="onBlurTmSummaryDesc($event)"
      (focus)="onFocusEL($event, template.summary.desc.text)"
      [attr.placeholder]="template.summary.desc.pl | translate"
      [sfContentEditableMaxLength]="appStateService.textMaxSizes.summary"

      [ngStyle]="{
          fontSize: appStateService.templateCurrentFontSize.summaryContent + 'px',
          lineHeight: appStateService.templateCurrentFontSize.summaryContentLineHeight + 'px',
          color: insideSidebar ? '#303030' : (selectedItem === template.summary ? tmTheme.summaryFontEditModeColor : tmTheme.summaryFontColor),
      }"

      sfClickableLinks
    ></p>
    <!--  (paste)="appStateService.onPastElementText($event, appStateService.textMaxSizes.summary, appStateService.textMaxSizes.summaryMaxHeight)" -->
  </div>

  @if (hasUnderLine) {
    <div class="sf-line summary-sf-line"></div>
  }

  <ng-template #AISummary>
    <div
      [nz-tooltip]="'AI Assistant'"
      class="summary-ai-help"
      [nzDropdownMenu]="menu"
      nz-dropdown
      nzTrigger="click"
      [nzOverlayClassName]="'ai-summary-c'"
      [nzClickHide]="false">
      <!--<i class="ph ph-robot"></i>-->
      <img src="assets/img/font-robot.svg" alt="Ai Assistant">
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu style="max-width: 70%; min-width: 200px;" class="ignore-click-outside">
        @if (!template.subTitle.text) {
          <li nz-menu-item class="ignore-click-outside fs-12">
            Add your profession first
          </li>
        } @else {
          @if (!summaryAiText) {
            <li nz-menu-item class="ignore-click-outside fs-12" (click)="getSummaryAI()">
              <button class="sf-btn-fifth small ai-generate ignore-click-outside" [class.loading]="aiStarted">
                Generate with AI
              </button>
            </li>
          } @else {
            <li nz-menu-item class="ignore-click-outside fs-12">
              <div class="ignore-click-outside">
                {{ summaryAiText }}
              </div>
            </li>
            <li nz-menu-divider class="ignore-click-outside"></li>
            <li nz-menu-item class="ignore-click-outside">
              <div class="d-flex ignore-click-outside">
                <button class="sf-btn-fifth small ai-apply" (click)="onApply()">Apply</button>
                <button
                  class="sf-btn-primary small ai-try-again ignore-click-outside"
                  (click)="getSummaryAI()"
                  [class.loading]="aiStarted">
                  Try Again
                </button>
              </div>
            </li>
          }
        }
      </ul>
    </nz-dropdown-menu>
  </ng-template>

}
