import {finalize} from "rxjs/operators";
import {TranslateModule} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {TemplateSectionTypes} from "@app/shared/enums";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NgStyle, NgTemplateOutlet} from "@angular/common";
import {TemplateLogicService} from "@app/shared/services";
import {TmEditorComponent} from "@app/templates/componenets";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {get_allTypes_tm_used_listSections} from "@app/shared/helpers";
import {AiHelpService} from "@app/shared/services/api/ai-help.service";
import {AppStateService} from "@app/shared/services/app-state.service";
import {NzDropDownDirective, NzDropdownMenuComponent} from "ng-zorro-antd/dropdown";
import {NzMenuDirective, NzMenuDividerDirective, NzMenuItemComponent} from "ng-zorro-antd/menu";
import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TemplateConfigs, TemplateConfigsSummary, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {ClickableLinksDirective, ContentEditableMaxLengthDirective, CtrlADirective} from "@app/shared/directives";

@Component({
  selector: 'sf-tm-summary',
  templateUrl: './tm-summary.component.html',
  styleUrls: ['./tm-summary.component.scss'],
  imports: [
    TmEditorComponent,
    CtrlADirective,
    NgStyle,
    ClickableLinksDirective,
    ContentEditableMaxLengthDirective,
    TranslateModule,
    NzMenuDirective,
    NzMenuItemComponent,
    NzDropdownMenuComponent,
    NzDropDownDirective,
    NzMenuDividerDirective,
    NzTooltipDirective,
    NgTemplateOutlet,
  ]
})
export class TmSummaryComponent implements OnInit {
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly aiHelpService = inject(AiHelpService);
  protected readonly appStateService = inject(AppStateService);
  protected readonly nzMessageService = inject(NzMessageService);
  protected readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input() hasUnderLine = true;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter();

  @Input() public insideSidebar = false;

  private focusedElText = '';

  protected summaryAiText = '';
  protected aiStarted = false;

  constructor() {
  }

  ngOnInit() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  protected onBlurSummaryTitle($event: FocusEvent, section: TemplateConfigsSummary) {
    section.title.text = ($event.target as HTMLHeadElement).innerHTML;

    if (this.focusedElText !== section.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurTmSummaryDesc($event: FocusEvent) {
    this.template.summary.desc.text = ($event.target as HTMLParagraphElement).innerHTML;

    if (this.focusedElText !== this.template.summary.desc.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onEnterDesc($event: Event) {
    const HTMLEl = $event.target as HTMLParagraphElement;

    if (!HTMLEl.innerText.length) {
      $event.preventDefault();
    }

    /*if (HTMLEl.clientHeight > this.appStateService.descSectionMaxHeight) {
      $event.preventDefault();
      this.nzMessageService.info('Too big size for section');
    }*/

    setTimeout(() => this.templateLogicService.splitToPages(this.template));
  }

  protected onBackspaceSummary($event: Event) {
    this.templateLogicService.descDeleteSubject.next(($event.target as HTMLElement).innerText);
  }

  protected handleCtrlA(item: any) {
    this.appStateService.editorState$.next(item);
  }

  protected handleMouseUp(item: any) {
    setTimeout(() => {
      this.appStateService.editorState$.next(item);
    });
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  protected getSummaryAI() {
    this.aiStarted = true;

    const skills = get_allTypes_tm_used_listSections(this.template)
      .filter((item) => item.type === TemplateSectionTypes.SKILLS)
      .map((item) => item.items.map((i) => i.text).join(','));

    this.aiHelpService.getSummaryAI({profession: this.template.subTitle.text, skills: skills[0]})
      .pipe(
        finalize(() => this.aiStarted = false),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res) => {
        this.summaryAiText = res.text;
      });
  }

  protected onApply() {
    this.template.summary.desc.text = this.summaryAiText;
    this.summaryAiText = '';

    this.saveChanges();
  }

}

